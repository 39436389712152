import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import { TextAndImage, Celebrate } from '@beelineloans/cx-library/src/components/layout/panels';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { Paragraph, H3, H2 } from '@beelineloans/cx-library/src/components/typography';
import SideImage from '@beelineloans/cx-library/src/components/SideImage';
import { applyEndpoint } from '@beelineloans/cx-library/src/utils/helpers';
import Box from '@beelineloans/cx-library/src/images/box.png';
import Chair from '@beelineloans/cx-library/src/images/chair.png';
import Laptop from '@beelineloans/cx-library/src/images/laptop.png';
import Cat from '@beelineloans/cx-library/src/images/cat-lay.png';
import Checklist from '@beelineloans/cx-library/src/images/checklist.png';
import { Button } from '../../../shared/GatsbyButton';

const Ul = styled.ul`
	padding-left: 20px;
`;

const Li = styled.li`
	margin-bottom: 15px;
`;

const H3Gap = styled(H3)`
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		padding-top: 100px;
	}
`;

const Step1Content = styled(Container)`
	.container-inner {
		padding-bottom: 0;

		${Paragraph}:last-of-type {
			margin-bottom: 0;
		}
	}

	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		display: none;
	}
`;

const Wrap = styled.div`
	@media only screen and (${props => props.theme.mediaBreakpoints.small}) {
		display: none;
	}
`;

const CenteredTextAndImage = styled(TextAndImage)`
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		.text-wrap,
		.image-wrap {
			align-self: center;
		}
	}
`;

const Step3TextAndImage = styled(CenteredTextAndImage)`
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		.container-inner {
			padding-bottom: 0;
		}
	}
`;

const Step3Container = styled(Container)`
	@media only screen and (${props => props.theme.mediaBreakpoints.small}) {
		display: none;
	}

	.container-inner {
		padding-top: 0;
		overflow: auto;
	}
`;

const Step4TextAndImage = styled(CenteredTextAndImage)`
	position: relative;
`;

const Half = styled.div`
	margin-top: 50px;

	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		width: 50%;
		float: left;
		box-sizing: border-box;

		&:first-of-type {
			padding-right: 57px;
		}

		&:last-of-type {
			padding-left: 57px;
		}
	}
`;

const ApplyButton = styled(Button)`
	margin-top: 50px;
`;

const Step1Text = () => (
  <>
    <H2>For the smoothest, fastest ride, here’s what you need&hellip;</H2>
    <Paragraph>
      The fastest way to apply is by linking directly to your bank, pay and tax
      info by logging in to your providers during the application. All
      information is encrypted end-to-end and none of your credentials are
      stored or accessible to anyone.
    </Paragraph>
    <Paragraph>
      Or, if you don’t have login information handy, upload the PDFs and it will
      only take a few extra minutes.{' '}
    </Paragraph>
    <Paragraph>
      If you need to break it up into a few sessions, that’s fine too. Your
      application progress is saved.
    </Paragraph>
  </>
);

const WhatYouNeedToApply = () => (
  <NavOnlyTemplate>
    <SEO
      path={CONST.LINKS.MAIN.WHAT_YOU_NEED_TO_APPLY}
      title="What you need to apply"
      description="For the smoothest, fastest application, here’s what you need..."
    />
    <Step1Content>
      <Step1Text />
    </Step1Content>
    <CenteredTextAndImage
      image={{
        src: Chair,
        mobileWidth: '315px',
        desktopWidth: '444px',
        mobileImageOnTop: true
      }}
    >
      <Wrap>
        <Step1Text />
      </Wrap>
      <H3Gap>Step 1</H3Gap>
      <Paragraph>
        <b>Nothing to do yet.</b>
      </Paragraph>
    </CenteredTextAndImage>

    <CenteredTextAndImage
      backgroundColor={Theme.colours.background.tanLight}
      left
      image={{
        src: Laptop,
        mobileWidth: '332px',
        desktopWidth: '475px',
        mobileImageOnTop: true
      }}
    >
      <H3>Step 2</H3>
      <Paragraph>
        <b>Login details for</b>
      </Paragraph>
      <Ul>
        <Li>Bank, savings or checking accounts.</Li>
        <Li>
          Investments accounts like stocks, CDs (Certificates of Deposit), money
          market or annuities.
        </Li>
        <Li>Retirement plans like 401k, IRA or mutual funds. </Li>
      </Ul>
      <Paragraph>
        <b>Or these PDFs</b>
      </Paragraph>
      <Ul>
        <Li>
          Banking, savings and checking accounts. Your two most recent
          statements (all pages).
        </Li>
        <Li>
          Investment accounts such as stocks, CDs (Certificates of Deposit),
          money market or annuities. Your most recent quarterly statement (all
          pages)
        </Li>
        <Li>
          Retirement plans such as 401k, IRA or mutual funds. Your most recent
          quarterly statement (all pages)
        </Li>
      </Ul>
    </CenteredTextAndImage>

    <Step3TextAndImage
      image={{
        src: Checklist,
        mobileWidth: '314px',
        desktopWidth: '420px',
        mobileImageOnTop: true
      }}
    >
      <H3>Step 3</H3>
      <Paragraph>
        <b>Login details for</b>
      </Paragraph>
      <Ul>
        <Li>Your payroll processor (eg. ADP)</Li>
        <Li>
          Your tax agent (eg. TurboTax, H&amp;R Block).
          <br />
          As a fallback, you can instantly retrieve your tax docs by linking to
          the IRS online.
        </Li>
      </Ul>
    </Step3TextAndImage>

    <Step3Container>
      <Half>
        <Paragraph>
          <b>Or these PDFs</b>
        </Paragraph>
        <Paragraph>If you’re employed:</Paragraph>
        <Ul>
          <Li>
            1 month of your most recent pay stubs (2 if you are paid biweekly
            and 4 if paid weekly).
          </Li>
          <Li>Most recent 2 years W-2s.</Li>
          <Li>Most recent 2 years complete federal tax returns.</Li>
        </Ul>
      </Half>
      <Half>
        <Paragraph>
          <b>Or these PDF&apos;s</b>
        </Paragraph>
        <Paragraph>If you’re self-employed:</Paragraph>
        <Ul>
          <Li>Most recent 2 years complete federal personal returns.</Li>
          <Li>
            Most recent 2 years complete federal business returns if you have
            25% or greater ownership in a Corporation or LLC.
          </Li>
          <Li>
            Most recent 2 years K-1 for any corporation or LLC you have any
            ownership in.
          </Li>
        </Ul>
      </Half>
    </Step3Container>

    <Step4TextAndImage
      backgroundColor={Theme.colours.background.tanLight}
      left
      image={{
        src: Cat,
        mobileWidth: '316px',
        desktopWidth: '492px',
        mobileImageOnTop: true
      }}
    >
      <SideImage imageAtTopMobile={false} />
      <H3>Step 4</H3>
      <Paragraph>
        <b>Provide your SSN (Social Security Number)</b>
      </Paragraph>
    </Step4TextAndImage>

    <CenteredTextAndImage
      image={{
        src: Box,
        mobileWidth: '327px',
        desktopWidth: '370px',
        mobileImageOnTop: true
      }}
    >
      <H3>Step 5</H3>
      <Paragraph>
        <b>Nothing again.</b>
      </Paragraph>
    </CenteredTextAndImage>
    <Celebrate
      heading={(
        <>
          Then say hello to your Purchase-Ready or Refi-Ready Approval.
          <br />
          <br />
          Sound good?
        </>
      )}
    >
      <ApplyButton
        to={applyEndpoint}
        supporting="Be done in 15 minutes"
        newWindow
      >
        Apply now
      </ApplyButton>
    </Celebrate>
  </NavOnlyTemplate>
);

export default WhatYouNeedToApply;
